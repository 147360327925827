import React, { useEffect } from "react";
import TGButton from "../../../../shared/tg-button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import {
  getROPMemberJoinDataError,
  getROPMemberJoinDataSuccess,
} from "../../slice/ropMemberJoinSlice";
import styles from "./rop-member-join.module.scss";
import { config } from "../../../../config/global";
import { useNavigate } from "react-router-dom";

const ROPMemberJoin: React.FC = () => {
  //This variable is used to call the reducer function in the ropMemberJoinSlice.
  const dispatch = useDispatch();
  //This is used to get the entire ROP Member Join component Redux state.
  const ropMemberJoinSelector = useSelector(
    (state: RootState) => state?.osciReducer?.ropmemberjoin.ropMemberJoinData
  );
  const API_URL = config.TEAMSITE_ROPMEMBERJOIN_API;

  const navigate = useNavigate();

  //This is to get the ROP Member Join component data from the localstorage.
  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: API_URL,
      isTeamsite: true,
      successAction: getROPMemberJoinDataSuccess,
      errorAction: getROPMemberJoinDataError,
    });
  }, [dispatch]);

  // const handleClick = () => {    useNavigate('/signup') }

  const url =
    "/" +
    window.location.pathname.split("/")[1] +
    ropMemberJoinSelector?.buttonURL;
  const onClickHandler = () => navigate(url);

  return (
    <div className={`${styles.ropMemberContainer} global-content-padding`}>
      <div className={styles.imageConatiner}>
        <img src={ropMemberJoinSelector?.image} alt="ROP Member Join" />
      </div>
      <div>
        <div>
          <div>
            <div>{ropMemberJoinSelector?.title}</div>
            <div>{ropMemberJoinSelector?.desc}</div>
          </div>
          <div>
            <a href={ropMemberJoinSelector?.linkURL}>
              {ropMemberJoinSelector?.linkText} &rarr;
            </a>
          </div>
        </div>
        <div>
          <TGButton
            label={ropMemberJoinSelector?.buttonText}
            variant="primary"
            customClass={styles.ropJoinNow}
            onClick={() => onClickHandler()}
          />
        </div>
      </div>
    </div>
  );
};

export default ROPMemberJoin;
